
import { defineComponent } from 'vue';

import { Actions as PaymentActions } from '@/store/enums/PaymentEnums';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'cancel-checkout-page',
  methods: {
    ...mapActions({
      cancelCheckout: PaymentActions.CANCEL_CHECKOUT,
    }),
    checkoutPlan(plan) {
      if (plan.pricing_key == null) return;

      const payload = {
        accountPlanId: plan.id,
      };

      return this.cancelCheckout(payload);
    },
  },
});
